import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';

import { useErrorNotification } from '../hooks/error-notification';

interface CsvExportButtonProps {
  onExport: () => Promise<void>;
}

export default function CsvExportButton({ onExport }: CsvExportButtonProps) {
  const [showErrorNotification] = useErrorNotification();
  const [exporting, setExporting] = useState<boolean>(false);

  const handleExport = async () => {
    setExporting(true);
    try {
      await onExport();
    } catch (err) {
      if (err instanceof Error) showErrorNotification(err);
      throw err;
    } finally {
      setExporting(false);
    }
  };

  return (
    <Button
      name="export"
      type="default"
      icon={<DownloadOutlined />}
      onClick={handleExport}
      loading={exporting}
    >
      CSV
    </Button>
  );
}
