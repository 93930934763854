import { App } from 'antd';
import { AxiosError } from 'axios';

export function useErrorNotification() {
  const { notification } = App.useApp();

  const showErrorNotification = (err: Error) => {
    notification.error({
      message:
        err instanceof AxiosError
          ? '通信エラーが発生しました'
          : '内部エラーが発生しました',
      description: err.message,
      placement: 'bottomLeft',
    });
  };

  return [showErrorNotification];
}

export function useGeneralErrorNotification() {
  const { notification } = App.useApp();

  const showGeneralErrorNotification = (message: string, err: Error) => {
    notification.error({
      message,
      description: err.message,
      placement: 'bottomLeft',
    });
  };

  return [showGeneralErrorNotification];
}
