import camelcaseKeys from 'camelcase-keys';

import { CostAccountingList } from '../domain/cost-accounting';
import { api } from '../lib';

const getAccountCostList = (jobNo?: string) =>
  api.accountApi.getAccountCostList(jobNo).then((res) => {
    const results = camelcaseKeys(res.data, { deep: true }).map(
      (accountCost) => ({
        ...accountCost,
        updateDate: new Date(accountCost.updateDate).toLocaleString(),
      })
    );
    return results as CostAccountingList[];
  });

const getAccountCostExportCsv = async (jobNo?: string) =>
  api.accountApi.getAccountCostExportCsv(jobNo).then((res) => res.data as Blob);

const dataSource = [...Array(100)].map((_, i) => ({
  projectNumber: i.toString().padStart(3, '0'),
  key: i.toString().padStart(3, '0'),
  jobNo: `604834${i.toString().padStart(3, '0')}`,
  projectName: `架空案件${i}`,
  manager: `BeeX 太郎${i}`,
  projectManager: `BeeX 花子${i}`,
  salesAmount: 999999999,
  normalCost: 999999999,
  grossMarginRate: 99.99,
  accountingSalesTotal: 999999999,
  accountingCost: 999999999,
  profitRate: 99.9,
  differenceFromNormalCost: 999999999,
  updatedDate: new Date(),
}));

// eslint-disable-next-line import/prefer-default-export
export { dataSource, getAccountCostList, getAccountCostExportCsv };
