import { Form, DatePicker, Col, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useForm from 'antd/es/form/hooks/useForm';
import { useRecoilState } from 'recoil';

import { getProjectListExportCsv } from '../../../helpers/cost-expense';
import {
  fromYearMonthState,
  toYearMonthState,
} from '../../../recoil/atoms/cost-expense';
import classes from '../../../styles/search.module.scss';
import { Search, CsvExportButton } from '../../common';
import { useGeneralErrorNotification } from '../../hooks/error-notification';

interface Props {
  onResize: () => void;
}

export default function CostExpenseListSearch({ onResize }: Props) {
  const [form] = useForm();
  const fromYearMonthInput = useWatch('fromYearMonth', form);
  const toYearMonthInput = useWatch('toYearMonth', form);
  const [fromYearMonth, setFromYearMonth] = useRecoilState(fromYearMonthState);
  const [toYearMonth, setToYearMonth] = useRecoilState(toYearMonthState);
  const [showGeneralErrorNotification] = useGeneralErrorNotification();

  const handleSearch = () => {
    if (form.isFieldsTouched()) {
      setFromYearMonth(fromYearMonthInput);
      setToYearMonth(toYearMonthInput);
    }
  };

  const exportCsv = async () => {
    if (fromYearMonthInput == null || toYearMonthInput == null) {
      showGeneralErrorNotification(
        'CSV出力エラー',
        new Error('対象日付を指定してください')
      );
      return;
    }
    const fromDate = fromYearMonthInput?.toDate();
    const toDate = toYearMonthInput?.toDate();
    const csvData = await getProjectListExportCsv(fromDate, toDate);
    const csvWithBom = `\ufeff${csvData}`;
    const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', 'cost-expense.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Search form={form} onSearch={handleSearch} onResize={onResize}>
      <Row style={{ marginTop: '15px' }}>
        <Col>
          <Form.Item
            name="fromYearMonth"
            label="開始年月"
            rules={[{ required: true }]}
            initialValue={fromYearMonth}
            className={classes.searchFormItem}
          >
            <DatePicker placeholder="YYYY/MM" picker="month" format="YYYY/MM" />
          </Form.Item>
        </Col>
        <Col style={{ paddingLeft: 12 }}>
          <Form.Item
            name="toYearMonth"
            label="終了年月"
            rules={[{ required: true }]}
            initialValue={toYearMonth}
            className={classes.searchFormItem}
          >
            <DatePicker placeholder="YYYY/MM" picker="month" format="YYYY/MM" />
          </Form.Item>
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
          <CsvExportButton onExport={exportCsv} />
        </Col>
      </Row>
    </Search>
  );
}
