import { useRecoilValue } from 'recoil';

import { getAccountCostExportCsv } from '../../../helpers/cost-accounting';
import { costAccountingJobNoState } from '../../../recoil/atoms/cost-accounting';
import CsvExportButton from '../../common/CsvExportButton';

export default function CostAccountingExport() {
  const jobNo = useRecoilValue<string>(costAccountingJobNoState);

  const exportCsv = async () => {
    const csvData = await getAccountCostExportCsv(jobNo);
    const csvWithBom = `\ufeff${csvData}`;

    const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', 'account-costs.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return <CsvExportButton onExport={exportCsv} />;
}
